export const termsOfUse = {
  content: `
## §1 Basic information
1. These regulations, hereinafter referred to as the Regulations, specify the principles of operation of the photonesto.com website, hereinafter referred to as the Website, available at www.photonesto.com, and the rules for the provision of electronic services through the Website by the Administrator who owns the Website.
2. The Regulations are published on the Website at www.photonesto.com/en/regulations/
3. The Website is owned by Codelane Sp. z o.o. from Cracow, ul. św Filipa 23/4, 31-150 Cracow, NIP number: 6762611449, REGON mumber: 521182420, KRS number: 0000952590.
4. The Website Owner can be contacted by e-mail at contact@codelane.tech or by phone at +48 530 662 499.
5. In the scope of Services Provided by Electronic Means, the Regulations are the regulations referred to in art. 8 of the Act of July 18, 2002 on the provision of electronic services (Journal of Laws of 2002, No. 144, item 1204, as amended).
## §2 Using the Website
1. The website was created to allow photographers to share galleries of their photos, which hereinafter are referred to as Photographs, Photography or User.
2. Access to the site is free and takes place using an access key, i.e. a verification code that allows one to log in to the site.
3. The photographer performing the photo session, hereinafter referred to as the Photographer, provides photos through a part of the system hereinafter referred to as the Photographer's Panel.
4. Access to the Photographer's Panel is possible by logging in using the data obtained during account registration at www.photonesto.com/en/sign-up/
5. The Photographer's panel permits creating online galleries and specifies which photos are included in the gallery.
6. In the Photographer's Panel the Photographer also specifies the gallery name and password for accessing the gallery. To enable users to have access to the gallery, it is necessary to provide them with this password.
7. The password must be unique within the Photographer's Panel.
8. The publication of a gallery is a paid service. The price a Photographer pays for publishing a gallery depends on the duration of the publication and the total amount of data taken by the photographs published in that gallery.
9. The price list specifying the fee for publishing a gallery can be found at www.photonesto.com/en/prices/
10. Inside the Photographer's Panel, the Photographer has the option of making payments using the Przelewy24 system to transfer the calculated fee to publish the gallery.
11. Payments are made using the Przelewy24 service, through one of the payment methods offered by PayPro SA, with its registered office in Poznań, 60-327 Poznań, ul. Kanclerskiej 15, registered by the District Court Poznań Nowe Miasto i Wilda, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego, KRS number: 0000347935, NIP 7792369887, i.e. via: payment card, fast transfer, online transfer, cash payment, online wallets, vouchers and credit cards.
12. The amount of payment may be greater than the fee for publishing the gallery itself. In such a case, the difference remains to be used by the Photographer for subsequent gallery publications.
13. Only persons who have acquired the access key legally, i.e. the access key has been provided by the person performing the photo session, who are hereinafter referred to as User or Users, are entitled to access the Photographs.
14. The access key allows access only to those Photographs that were taken during a photo session with the person to whom the access key was provided or a photo session carried out with their consent. It is not possible to access a photo session other than with the access key referred to in the previous sentence.
15. Logging in to the Website is possible by entering the access key in the space provided. After typing the access key in the space provided, log in by clicking on the specially marked field or using the ENTER key.
16. After logging in to the website, a gallery containing Photographs taken during the photo session will appear.
17. Photographs are stored on the Website for the period specified by the Photographer when publishing the gallery. After this period, the Photographs will be removed from the Website.
## §3 Trial period
1. Each new user of the website is entitled to 1 (one) free trial period to test the functionality of the website.
2. The trial period gives the user free access to the website's functionality for a period of 14 days from the moment of setting up the account.
3. As part of the trial period, a Photographer may publish up to 10 photo galleries or private sessions.
4. Abuse of the trial period to avoid fees may result in the account and all content being blocked.
## §4 Rights and obligations
1. The Website reserves the right to temporarily cease to provide services due to the repair or modernization of the Website. Information about these types of activities will appear on the Website.
2. The User is obliged to use the Website in a manner consistent with the law and decency, having regard to respect for personal rights and material copyrights of the Website Owner and personal copyrights of Photography and third parties.
3. A Photographer may not post content that violates the rights of other people or is contrary to the law, decency or social coexistence.
4. The Website Owner may delete data containing the kinds of prohibited content mentioned above if he finds that it has been placed on the Account.
5. The User is solely responsible for creating, managing, editing, browsing, sorting, deleting and controlling in any other way data stored in the Account.
6. The user may only use the account for lawful purposes.
## §5 Minimum technical requirements
To use the Website, you must have the following equipment:
1. PC, smartphone, tablet or other device usually used to browse websites with the most up-to-date software version, by which it is possible to download and save files from the Internet.
2. Internet connection (at least 10 Mbps recommended);
3. current version of Firefox, Chrome, Safari or Microsoft Edge web browser;
4. enabled JavaScript, frames and CSS styles.
## §6 Website responsibility
1. Despite the application of the highest security standards, the Website cannot be held responsible for temporary and unexpected disabling of the Website's functionalities as a result of hacker attacks, random causes, natural disasters, force majeure or other circumstances beyond the Website's control.
2. The Website Owner is not responsible for the consequences of the Photographer's violation of property or personal copyrights or any rights of third parties in connection with the use of the Website in particular in connection with the publication of photos by the Photographer on the Website.
3. The Website Owner is not responsible for the failure or improper performance by the Photographer or of the Customer from obligations arising from the legal relationship binding them on the basis of a separate agreement, as well as for the violation by the Customers or third parties of the copyright and property rights of Photographers.
## §7 Complaint procedure
1. In the event of unsatisfactory quality of Services provided by the Website Owner or other entities on his behalf, or in the event of the Website Owner's failure to perform, or improper performance of obligations towards Photographers, the Photographer shall be entitled to file a complaint.
2. Complaints should be reported by sending an e-mail to contact@codelane.tech, with the subject "complaint" in the subject of the message.
3. A correctly submitted complaint should contain at least the following data:
* Photographer's name (name and surname, correspondence address, e-mail address, and in the case of Photographers who are not natural persons, name (company name), address of the registered office and details of the natural person authorized to handle matters related to the complaint and its e-mail address);
* brief description of the comments and reservations submitted;
* photo documentation if possible.
4. Complaints are handled by the Website Owner immediately when possible, at the latest within 14 days.
## §8 Dispute resolution
1. In the event of disputes, the Website suggests using amicable settlement methods.
2. Any litigation will be subject to jurisdiction in the court competent for the Website Owner.
3. The website informs that at http://ec.europa.eu/consumers/odr there is an online platform for settling disputes between consumers and entrepreneurs. It is a one-stop shop for out-of-court settlement of disputes regarding contractual obligations under an online sales contract or service contract.
4. In the event of a dispute with the User, the Website proposes to use mediation at the Voivodship Inspectorate of Trade Inspection, competent for the seat of the Consumer, or use arbitration at the Permanent Consumer Arbitration Court competent for the seat of the Consumer.
## §9 Final Provisions
1. The Regulations entered into force on August 30, 2018
2. The Service Provider reserves the right to change the Regulations after 14 days from posting relevant information on the Website and sending information to Users via e-mail.
3. The provisions of the Regulations do not exclude any rights of Consumers entitled to them under generally applicable law.
4. In cases not covered by the Regulations, the provisions of generally applicable law shall apply.`,
}
